<!-- 页面登录 -->
<template>
  <div class="accountloging">
  	<div class="top">
  		<div class="title">
  			<img :src="logos" @click='linklogo'>
  			<p>欢迎登录</p>
  		</div>
  	</div>
  	<div class="bg">
   		<div class="box">
   			<login @taggerLogOrReg='taggerLogOrReg'></login>
  			<!-- <register  @taggerLogOrReg='taggerLogOrReg'></register> -->
   		</div>
  	</div>
  </div>
</template>
<script>
import register from '@/components/register/register.vue';//注册
import login from '@/components/login/login.vue';//登录
export default {
  name: 'accountloging',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  props: {
    msg: String
  },
  components:{
    register,
    login
  },
  data(){
  	return {
      htmltitle:'登录-微微定',
      logos:null
  	}
  },
  methods:{
  	taggerLogOrReg(){
  		// 切换注册
      this.$router.push('/accountregister.html')
  	},
    linklogo(){
      // logo跳转
      if(this.$store.state.logoUrl){
        window.location.href=this.$store.state.logoUrl
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
        // 加载
        var _this =this;
        let b = function() {
          return new Promise(function(resolve, reject) {
              _this._axios({then:()=>{
                // logo
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAdvertisingImg?type=2',_this.keys())
                  .then(res => {
                    if(res.data.Success){
                      _this.logos = _this._src + res.data.Data.List[0].AdvertisingPath
                      _this.$store.dispatch("cmtLogoUrl",res.data.Data.List[0].LinkUrl);//改状态-logourl
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
          })
        }
        this._reduce([b])
        .then(()=> {
          if(window.sessionStorage.getItem('isReadFirst')){
              window.sessionStorage.removeItem('isReadFirst')
              // this.$store.dispatch("cmtCap",true);//显示隐藏
              $("#app").css('opacity',1)
          }
          window.scrollTo(0,0);//刷新置顶
          console.log('头部加载完成')
          //this._NProgress.done();//关闭加载动画
          let _footPomiseB = this.$store.state.footPomiseB
          this._reduce([_footPomiseB])
          .then(()=> {
            console.log('内容队列加载')
          })
          .catch(function(e) {
              console.log(e)
          })
        })
        .catch(function(e) {
            console.log(e)
        })
        // end
    })
  }
}
</script>
<style lang="scss">
	// body {
	// 	background: #fff;
	// }
</style>
<style  lang="scss" scoped>
.accountloging {
  background: #fff;
  .top {
  	height: 134px;width: 1200px;margin:0 auto;
  	.title {
  		display: flex;align-items: center;height: 134px;
  		img {
  			display: block;cursor: pointer;margin-top:5px;
  		}
  		p   {
  			font-size: 24px;color:#fb429d;letter-spacing: 3px;position: relative;margin-left:40px;
  			&:after {
  				content: '';display: block;width: 1px;height: 48px;background: #fb429d;position: absolute;top:-6px;left:-16px;
  			}
  		}
  	}
  }
  .bg {
  	width: 100%;height: 622px;background: url('../assets/img/account/bg.jpg') center center no-repeat;
  	.box {
  		width: 1200px;margin:0 auto;height: 622px;
  		/deep/.register {
			position: static;width: 100%;height: 622px;background: none;
			.content {
				position: relative;top:auto;left:auto;float:right;margin-top:40px;
				.close {
					display: none;
				}
			}
			.content1 {
				top:auto;
			}
	  	}
  	}

  }
  @media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
    .bg {
      width:1200px;
    }
  }
}
</style>
